.css-1pxa9xg-MuiAlert-message {
  font-size: 17px;
}
.my-course-details{
  margin-top: 30px;
  margin-bottom: 40px;
}
.my-course-details .sub{
  font-weight: 700;
  font-size: 23px;
  margin-bottom: 20px;
}

.edu-footer-widget a:hover{
  color: lightgray !important;
}