// @import 'default/variables';
// @import 
// 'default/animations',
// 'default/common',
// 'default/extend',
// 'default/forms',
// 'default/mixins',
// 'default/reset',
// 'default/reset',
// 'default/shortcode',
// 'default/spacing',
// 'default/typography';

// @import 'blog/blog-details','blog/blog-grid','blog/comments','blog/sidebar';

// @import 
// 'elements/about',
// 'elements/backtotop',
// 'elements/badge',
// 'elements/brands',
// 'elements/button',
// 'elements/calltoaction',
// 'elements/card',
// 'elements/checkout',
// 'elements/chooseus',
// 'elements/coming-soon',
// 'elements/contactus',
// 'elements/countdown',
// 'elements/counterup',
// 'elements/coursesidebar',
// 'elements/event',
// 'elements/feature',
// 'elements/meta',
// 'elements/newsletter',
// 'elements/pagination',
// 'elements/section-animation',
// 'elements/section-title',
// 'elements/service',
// 'elements/shop',
// 'elements/single-elements',
// 'elements/slickslider',
// 'elements/social-share',
// 'elements/team',
// 'elements/testimonial',
// 'elements/video-popup',
// 'elements/winner',
// 'elements/workshop';

// @import 'elements/brand.scss','elements/overall.scss';
// @import 'footer/copyright','footer/footer';
// @import 'header/header-quote','header/header','header/mobilemenu','header/nav','header/search-popup';
// @import 'template/banner','template/cart','template/course-details','template/event-details','template/preview','template/product-details';

// @import '../css/animation.css';
// @import '../css/eduvibe-font.css';
// @import '../css/remixicon.css';
// @import '../css/slick.css';
// @import '../css/slicktheme.css';

@import '../main.css';

.edu-header{
    .logo a{
        display: flex;
        gap: 10px;
        align-items: center;

        .name{
            color: #0096DB;
            line-height: 1.2;
            font-size: 15px;
            font-weight: 800;
        }
    }
}
@media (max-width: 1300px){
    .edu-btn{
        font-size: 12px !important;
    }
}
@media (max-width: 1220px){
    .edu-btn{
        font-size: 11px !important;
    }
}
